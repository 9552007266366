import { useCookie } from '#app'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $get } = useApi()
  const authToken = useCookie(COOKIE_KEYS.authToken)
  const userStore = useUserStore()
  if (!authToken.value) {
    return navigateTo('/login')
  }

  try {
    let localUser = userStore.user

    if (!localUser) localUser = await $get('/api/v1/auth')

    if (userStore?.setUser) userStore.setUser(localUser)

    return true
  } catch (error) {
    console.error(error)
    return navigateTo('/login')
  }
})
